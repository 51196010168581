/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: "/",
    name: "Ana Sayfa",
    slug: "home",
    icon: "HomeIcon",
    i18n: "Home",
  },
  {
    url: "/about",
    name: "Biz",
    slug: "page2",
    icon: "InfoIcon",
    i18n: "About",
  },
  {
    url: "/services",
    name: "Hizmetler",
    slug: "services",
    icon: "BookmarkIcon",
    i18n: "Services",
  },
  {
    url: "/faq",
    name: "Sık Sorulan Sorular",
    slug: "faq",
    icon: "HelpCircleIcon",
    i18n: "Faq",
  },
  {
    url: "/references",
    name: "Referanslar",
    slug: "references",
    icon: "UsersIcon",
    i18n: "References",
  },
  { url: "/support",name: "Destek",slug: "Destek",icon: "DownloadIcon",},
  {url: "/shop",name: "Mağaza",slug: "shop",icon: "ShoppingBagIcon",i18n: "Mağaza"},
  {
    url: "/contact",
    name: "İletişim",
    slug: "contact",
    icon: "PhoneCallIcon",
    i18n: "Contact",
  },
]
